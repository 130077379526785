import React from 'react'

import "./Footer.sass"

import linkedin from "../images/in.png"
import fb from "../images/fb.png"
import ig from "../images/ig.png"

const Footer = () => (
  <div className="footer">
    <a href="https://www.linkedin.com/in/nanati/" target="_blank" rel="noreferrer">
      <img className='in' src={linkedin} alt="linkedin-logo"/>
    </a>
    <a href="https://www.facebook.com/nativix/" target="_blank" rel="noreferrer">
      <img className='fb' src={fb} alt="facebook-logo"/>
    </a>
    <a href="https://www.instagram.com/nativix/" target="_blank" rel="noreferrer">
      <img className='ig' src={ig} alt="instagram-logo"/>
    </a>
  </div>
)

export default Footer