import React from 'react'
import { Link } from "gatsby"

import "./Navigation.sass"

const Navigation = () => (
    <div className="navigation">
        <div className="wrapper">
            <Link to="/">HOME</Link>
            <Link to="/resume">RESUME</Link>
            <Link to="/contact">CONTACT</Link>
        </div>
    </div>
)

export default Navigation