import React from 'react'
import Helmet from 'react-helmet'

import favicon from "../images/icon.png"

const Head = () => (
  <div>
    <Helmet
      meta={[
        { name: 'description', content: '' },
        { name: 'keywords', content: 'ios-dev, swift, nati, tech-women, ios, developer' }
      ]}>
      <title>Nati Lara - iOS Developer</title>
      <link rel="shortcut icon" href={favicon}></link>
    </Helmet>
  </div>
)

export default Head